<mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
    <mat-tab label="Console">
        <mat-accordion multi>
            <mat-expansion-panel *ngIf="isTrainer">
                <mat-expansion-panel-header>
                    <mat-panel-title>Créer une partie enseignant</mat-panel-title>
                </mat-expansion-panel-header>
              <img src="assets/help/console_1-3.png">
                <p>
                  En tant que professeur, vous pouvez créer autant de parties que vous le voulez pour votre classe, sur l’année en cours.
                  Pour accéder aux parties, cliquez sur le menu puis sur le bouton <b>Gérer mes parties</b>.
                </p>
                <img src="assets/help/console_1-1.png">
                <p>
                  Cette page liste l’ensemble de vos parties, qu’elles soient en cours ou terminées.
                  Pour créer une partie, cliquez sur le bouton <b>Créer partie</b> en haut à droite de la page.
                </p>
                <img src="assets/help/console_1-2.png">
                <p>
                  Ici, vous pourrez définir les différents paramètres de la partie qui sera accessible à vos élèves.
                </p>
                <ul>
                  <li>La durée des tours :</li>
                    <ul>
                      <li>2 semaines par tour équivalent à 240 tours (environ 5h de jeu)</li>
                      <li>3 semaines par tour équivalent à 44 tours (environ 1h de jeu)</li>
                      <li>Moins un tour passe de semaines, plus vous pouvez être précis dans la gestion de l’exploitation et plus la partie est longue</li>
                    </ul>
                  <li>Le scénario de départ :</li>
                    <ul>
                      <li>Vous choisissez un scénario de départ, parmi les scénarios disponibles dans le jeu</li>
                      <li>De nouveaux scénarios sont régulièrement ajoutés au jeu</li>
                    </ul>
                  <li>Les conditions de victoires :</li>
                    <ul>
                      <li>Pour gagner, les joueurs doivent atteindre un score par indicateur et un score global (la moyenne des indicateurs)</li>
                      <li>Vous pouvez définir l’ensemble de ces scores</li>
                      <li>Par défaut, notre jeu propose d’atteindre un score par indicateurs de 3 et un score global de 4</li>
                    </ul>
                  <li>Les objectifs obligatoires :</li>
                    <ul>
                      <li>Vous pouvez rendre une recherche ou un choix stratégique obligatoire pour vos élèves</li>
                      <li>Ces objectifs sont optionnels, vous n’êtes pas obligés d’en choisir</li>
                      <li>Les objectifs disponibles dépendent du scénario sélectionné</li>
                    </ul>
                  <li>Les dates de départ et de fin :</li>
                    <ul>
                      <li>Il s’agit de la date à partir de laquelle et jusqu’à laquelle il sera possible pour vos élèves de lancer une partie</li>
                      <li>Une fois la date de fin atteinte, il n’est plus possible de lancer une nouvelle partie ou de rejoindre une partie en cours</li>
                    </ul>
                  <li>La classe participante :</li>
                    <ul>
                      <li>Il s’agit de choisir la classe qui va participer à cette partie, elle sera la seule à y avoir accès</li>
                      <li>Il vous est possible de diviser les classes en sous-groupe, chaque sous-groupe pouvant jouer des parties paramétrées différemment</li>
                    </ul>
                  <li>Le nom de votre partie :</li>
                    <ul>
                      <li>Vous pouvez choisir le nom que vous désirez pour votre partie</li>
                      <li>Ce nom permettra à vos élèves de localiser la partie qui a été paramétrée pour eux, il doit donc être reconnaissable</li>
                    </ul>
                </ul>
              <p>
                Une fois la création finalisée, vos élèves reçoivent automatiquement un mail leur indiquant le nom de la partie à rejoindre.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isTrainer">
                <mat-expansion-panel-header>
                    <mat-panel-title>Rejoindre une partie enseignant</mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                  Si vous êtes un élève et que votre professeur vous a invité à rejoindre une partie, vous devez commencer par créer un compte de jeu.
                  Vous pouvez retrouver le bouton <b>Créer un compte</b> sur l’accueil du site en haut à droite.
                </p>
                <img src="assets/help/console_2-1.png">
                <p>
                  Ce compte vous permettra d’accéder à toutes les futures parties « enseignant » et « challenge ».
                  Utilisez une adresse mail connue des organisateurs de la partie.
                </p>
                <p>
                  Une fois votre compte créé, vous pouvez vous rendre dans le jeu :
                </p>
                <ul>
                  <li>En le téléchargeant gratuitement avec <a href="https://store.steampowered.com/app/1605430/Roots_of_Tomorrow/?l=french">Steam</a></li>
                  <li>En le téléchargeant directement depuis <a href="https://rootsoftomorrow.gamabilis.com/home">notre site</a></li>
                </ul>
                <img src="assets/help/console_2-2.png">
                <p>
                    Une fois le jeu lancé, cliquez sur le bouton <b>Rejoindre partie</b> et renseignez vos informations de compte :
                </p>
                <img src="assets/help/console_2-3.png">
                <p>
                    Vous pouvez ensuite constater que la partie créée par votre enseignant est bien accessible dans vos <b>Parties en cours</b>.
                    En cas de doute, demandez le nom de la partie à votre enseignant.
                </p>
                <img src="assets/help/console_2-4.png">
                <p>
                    Pour rejoindre cette partie, cliquez sur le bouton <b>Lobby</b>.
                </p>
                <img src="assets/help/console_2-5.png">
                <p>
                  Une fois le Lobby rejoint, vous avez accès aux paramètres de la partie :
                </p>
                <ul>
                  <li>La liste des participants</li>
                  <li>La durée des tours</li>
                  <li>Le score global que vous devez atteindre</li>
                  <li>Le score par indicateur minimum à atteindre</li>
                  <li>Les objectifs supplémentaires</li>
                  <li>L’échéance de la partie</li>
                </ul>
              <p>
                Tant que l’échéance n’est pas atteinte, vous pouvez lancer une partie.
                Il est à noter que votre partie doit forcément être commencée et terminée entre les dates de début (sous le bouton « jouer ») et la date de fin (en haut de la fenêtre).
              </p>
                <img src="assets/help/console_2-6.png">
                <p>
                  Lors des parties « enseignant », les seules sauvegardes possibles sont les sauvegardes automatiques.
                  Elles ont lieu à chaque fin de tour. Il vous sera donc impossible de créer vous-même une sauvegarde.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isManager">
                <mat-expansion-panel-header>
                    <mat-panel-title>Créer une partie challenge</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/console_3-3.png">
                <p>
                  Le nombre de parties que vous pouvez créer en tant qu’organisateur de tournois n’est pas limité.
                  Pour accéder aux parties, cliquez  sur le menu puis sur le bouton <b>Gérer mes parties</b>.
                </p>
                <img src="assets/help/console_3-1.png">
                <p>
                  Cette page liste l’ensemble de vos parties, qu’elles soient en cours ou terminées.
                  Pour créer une partie, cliquez sur le bouton <b>Créer partie</b> en haut à droite de la page.
                </p>
                <img src="assets/help/console_3-2.png">
                <p>
                  Ici, vous pourrez définir les différents paramètres de la partie qui sera accessible aux participants.
                </p>
                <ul>
                  <li>La durée des tours :</li>
                  <ul>
                    <li>2 semaines par tour équivalent à 240 tours (environ 5h de jeu)</li>
                    <li>3 semaines par tour équivalent à 44 tours (environ 1h de jeu)</li>
                    <li>Moins un tour passe de semaines, plus vous pouvez être précis dans la gestion de l’exploitation et plus la partie est longue</li>
                  </ul>
                  <li>Le scénario :</li>
                  <ul>
                    <li>Vous choisissez un scénario de départ, parmi les scénarios disponibles dans le jeu</li>
                    <li>De nouveaux scénarios sont régulièrement ajoutés au jeu</li>
                  </ul>
                  <li>Les conditions de victoires :</li>
                  <ul>
                    <li>Pour gagner, les joueurs doivent atteindre un score par indicateur et un score global (la moyenne des indicateurs)</li>
                    <li>Vous pouvez définir l’ensemble de ces scores</li>
                    <li>Par défaut, notre jeu propose d’atteindre un score par indicateurs de 3 et un score global de 4</li>
                  </ul>
                  <li>Les objectifs obligatoires :</li>
                  <ul>
                    <li>Vous pouvez rendre une recherche ou un choix stratégique obligatoire</li>
                    <li>Ces objectifs sont optionnels, vous n’êtes pas obligés d’en choisir</li>
                    <li>Les objectifs disponibles dépendent du scénario sélectionné</li>
                  </ul>
                  <li>Les dates de départ et de fin :</li>
                  <ul>
                    <li>Il s’agit de la date à partir de laquelle et jusqu’à laquelle il sera possible pour vos élèves de lancer une partie</li>
                    <li>Une fois la date de fin atteinte, il n’est plus possible de lancer une nouvelle partie ou une partie en cours</li>
                  </ul>
                  <li>Le nombre de participants :</li>
                  <ul>
                    <li>Vous pouvez définir ou non un nombre maximum de participants</li>
                  </ul>
                  <li>Le nom de votre partie :</li>
                  <ul>
                    <li>Vous pouvez choisir le nom que vous désirez pour votre partie</li>
                    <li>Ce nom permettra aux participants de localiser la partie qui a été paramétrée pour eux, il doit donc être reconnaissable</li>
                  </ul>
                  <li>La confidentialité de votre partie :</li>
                  <ul>
                    <li>Si la partie est publique, n’importe qui pourra y accéder depuis la liste des tournois en jeu</li>
                    <li>Si la partie est privée, les participants auront besoin d’un code qui sera automatiquement généré</li>
                  </ul>
                </ul>
              <p>
                Une fois la création finalisée, la partie est accessible depuis le jeu, dans le menu « Rejoindre une partie ».
                Si vous avez choisi une confidentialité privée, vous devez envoyer le code généré précédemment à l’ensemble des participants, sans quoi ils ne pourront pas accéder à la partie.
              </p>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isManager">
                <mat-expansion-panel-header>
                    <mat-panel-title>Rejoindre une partie challenge</mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Si vous êtes un joueur et que vous voulez rejoindre une partie challenge, vous devez d'abord créer un compte de jeu.
                    Vous pouvez retrouver le bouton <b>Créer un compte</b> sur l’accueil du site en haut à droite.
                </p>
                <img src="assets/help/console_4-1.png">
                <p>
                    Ce compte vous permettra d’accéder à toutes les parties.<br>
                    Une fois votre compte créé, vous devez vous rendre dans le jeu.
                    Vous pouvez l’installer via <a href="https://store.steampowered.com/app/1605430/Roots_of_Tomorrow/?l=french">Steam</a>,
                    mais si vous n’êtes pas autorisé à installer ce logiciel, une version “sans Steam” est accessible depuis le site :
                </p>
                <img src="assets/help/console_4-2.png">
                <p>
                    Une fois le jeu lancé, cliquez sur le bouton <b>Rejoindre partie</b> et rentrez vos informations de compte :
                </p>
                <img src="assets/help/console_4-3.png">
                <p>
                    Vous pouvez ensuite voir toutes les parties challenge créées dans <b>Liste tournois</b>.
                </p>
                <img src="assets/help/console_4-4.png">
                <p>
                    Ici, vous pouvez voir tous les tournois en cours et terminés. Pour rejoindre une partie,
                    il suffit de cliquer sur le bouton <b>Rejoindre</b> en face de la partie.
                </p>
                <img src="assets/help/console_4-5.png">
                <p>
                    Si c’est une partie publique, vous rejoignez directement le lobby. Si c’est une partie privée,
                    vous devez entrer le code fourni par l’organisateur. Vous pouvez aussi très bien chercher automatiquement une partie privée via
                    le bouton <b>Rejoindre un tournoi privé</b>.
                </p>
                <img src="assets/help/console_4-6.png">
                <p>
                    Vous vous retrouvez alors dans le lobby, une “salle d’attente” qui vous permet de voir la liste des participants ainsi que les règles.<br>
                    Vous pouvez <b>Jouer</b> à cette partie seulement dans la période de temps définie par l’organisateur.
                    Le bouton peut être grisé en dehors de cette période.
                </p>
                <img src="assets/help/console_4-7.png">
                <p>
                    Le jeu sauvegarde automatiquement à chaque tour, aucune autre sauvegarde n’est autorisée.
                    Quand vous quittez votre partie, vous la retrouvez donc dans l’état où vous l’aviez laissée.<br>
                    Pour retrouver plus facilement votre partie, vous pouvez aller dans <b>Parties en cours</b> :
                </p>
                <img src="assets/help/console_4-8.png">
                <img src="assets/help/console_4-9.png">
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Voir les classements des parties</mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    En tant qu'enseignant, organisateur ou joueur, vous avez moyen de suivre l’évolution des parties.<br>
                    Depuis le site web, vous pouvez aller voir les <b>Détails</b> de toutes vos parties créées (si vous êtes enseignant ou organisateur) :
                </p>
                <img src="assets/help/console_5-1.png">
                <p>
                    Toujours depuis le site, la <b>Liste des tournois</b> est accessible à tout le monde.
                    Les <b>Classements</b> des parties challenge y sont disponibles.
                </p>
                <img src="assets/help/console_5-2.png">
                <p>
                    Depuis le jeu, la <b>Liste des tournois</b> et leurs <b>Classements</b> est aussi disponible à tout le monde :
                </p>
                <img src="assets/help/console_5-3.png">
                <p>
                    Les <b>Parties en cours</b> que vous avez rejointes permettent aussi d’accéder à ces <b>Détails</b> :
                </p>
                <img src="assets/help/console_5-4.png">
                <p>
                    Dans chaque cas, vous pouvez accéder aux infos suivantes :
                </p>
                <ul>
                    <li>Le nom de chaque participant.</li>
                    <li>À quel tour de jeu il en est et sur combien.</li>
                    <li>Est-ce que ce participant est en cours de jeu, a gagné ou a perdu.</li>
                    <li>Son temps de jeu réel.</li>
                    <li>Son score global.</li>
                </ul>
                <img src="assets/help/console_5-5.png">
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="isTrainer">
                <mat-expansion-panel-header>
                    <mat-panel-title>Créer une classe</mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                  Avant de pouvoir créer une partie « enseignant », vous devez créer une classe.
                  Cela constitue un ensemble de 30 joueurs au maximum, les élèves, que vous pourrez sélectionner lors de la création de votre partie.
                  Les classes peuvent être divisées en sous-groupes, permettant de créer plusieurs parties simultanées par classe.
                </p>
                <p>
                  Pour accéder à la création des classes, rendez-vous sur le site et connectez-vous à votre compte enseignant.
                  Vous pouvez ensuite accéder à la page <b>Gérer mes classes</b> depuis le menu.
                </p>
                <p>
                  Vous trouverez ici l’ensemble de vos classes pour l’année en cours, ainsi que les classes des années passées.
                  Un « crédit » donne la possibilité de créer une classe pour l’année en cours, dans la limite de 30 élèves.
                </p>
                <p>
                  Pour créer une nouvelle classe, cliquez sur le bouton Créer classe en haut à droite de la page.
                  Vous pourrez ensuite renseigner les informations suivantes :
                </p>
                <ul>
                  <li>Les généralités sur votre classe</li>
                    <ul>
                      <li>Son nom</li>
                      <li>Votre établissement</li>
                      <li>L'année scolaire en cours</li>
                    </ul>
                </ul>
                <ul>
                  <li>La liste des élèves</li>
                  <ul>
                    <li>Leurs noms</li>
                    <li>Votre établissement</li>
                    <li>Leurs adresses e-mails</li>
                  </ul>
                </ul>
              <img src="assets/help/console_6-1.png">
                <p>
                  Une fois qu’une classe est créée, elle peut être modifiée à tout moment depuis la page <b>Gérer mes classes</b>.
                  Vous pouvez modifier :
                </p>
                <ul>
                    <li>La liste des élèves</li>
                    <li>Le nom de la classe</li>
                    <li>Les sous-groupes constituant la classe</li>
                </ul>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Comment jouer">
        <mat-accordion multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Interface de jeu</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_1.png">
                <ul>
                    <li>En haut à gauche se trouvent tous les menus (paramètres, marché, finances, équipe…).</li>
                    <li>En bas à gauche, vous pouvez cliquer sur la flèche pour voir où en sont vos différents scores.</li>
                    <li>En haut à droite se trouve votre trésorerie.</li>
                    <li>En-dessous se trouve une carte que vous pouvez déplier avec la flèche.
                        Cliquer sur un endroit de la carte permet de déplacer la caméra plus rapidement.</li>
                    <li>Encore en-dessous, en bas à droite, se trouvent la date, la météo, le nombre de tours restant ainsi que le bouton pour
                        accéder au tour suivant. Ce bouton téléporte aussi sur les actions obligatoires s’il y en a dans ce tour.</li>
                    <li>Quand on clique sur un bâtiment ou une parcelle, une roue d’actions s’affiche avec toutes les actions disponibles,
                        ainsi qu’un bouton d’infos donnant des éléments assez importants.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Tours de jeu</mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Le jeu se joue en tour par tour. Une partie dure 10 ans, et chaque tour dure 2 semaines par défaut. Ce qui fait environ 240 tours de jeu.
                </p>
                <p>
                    La durée des tours peut varier en mode partie challenge ou enseignement, de 2 semaines à 3 mois. Cela permet de réduire le temps de jeu réel.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Contrôles de jeu</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_3.png">
                <p>
                    Dans le menu paramètres en haut à gauche, vous pouvez retrouver cet écran.
                </p>
                <ul>
                    <li>Le clic gauche permet de sélectionner les bâtiments, parcelles, de valider et annuler, et d’ouvrir les menus.</li>
                    <li>La molette permet de zoomer / dézoomer.</li>
                    <li>Pour déplacer la caméra, vous pouvez maintenir le clic gauche et déplacer la souris.
                        Ou bien approcher le curseur d’un des 4 bords de l’écran pour déplacer la caméra dans cette direction.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Actions</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_4-1.png">
                <ul>
                    <li>Chaque bâtiment et parcelle vous donne accès à des <b>Actions</b> lorsque vous cliquez dessus (nourrir, protéger, vendre, soigner, améliorer un bâtiment…).</li>
                    <li>Certaines actions se répètent chaque tour, d’autres interviennent de façon ponctuelle dans l’année.
                        Ces dernières vous seront notifiées pour ne pas que vous les ratiez.</li>
                    <li>Lisez bien les infobulles de chaque action, ce sont elles qui vous apprennent leur fonctionnement.</li>
                </ul>
                <img src="assets/help/jouer_4-2.png">
                <ul>
                    <li>Après avoir choisi une action, plusieurs <b>Techniques</b> sont généralement proposées.
                        Ces techniques peuvent avoir une influence différente sur la charge de travail, les coûts, les indicateurs de victoire.</li>
                    <li>Vous devez ensuite choisir une ou plusieurs personnes de votre équipe pour réaliser cette tâche. Attention à leur charge de travail.</li>
                    <li>Lisez bien les infobulles pour en apprendre plus sur ces impacts et leurs fonctionnements.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Scores</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_5-1.png">
                <ul>
                    <li>Le <b>panneau de scores</b> en bas à gauche se déplie grâce à la flèche.
                        Il permet d’un coup d'œil de connaître l’état actuel de l’exploitation dans les différents critères.</li>
                    <li>Chaque action et choix fait dans le jeu a un impact sur ces critères. C’est ce qui régit le jeu.</li>
                    <li>Dans une partie classique, il faut avoir 3 minimum dans chaque critère et 4 au score global à la fin de la partie pour gagner.
                        Ces objectifs peuvent changer en mode partie challenge ou enseignement.</li>
                    <li>Si un de vos scores passe sous 1 trop longtemps, ou si vous n’atteignez pas les scores requis à la fin de la partie,
                        c’est game over.</li>
                </ul>
                <img src="assets/help/jouer_5-2.png">
                <ul>
                    <li>En cliquant sur le <b>bouton Score</b> du panneau de scores, une fenêtre détaillant tous les scores s’ouvre.</li>
                    <li>Vous pouvez voir les différents indicateurs qui régissent chaque critère de victoire.</li>
                    <li>Les actions et choix ont une influence sur ces indicateurs. Vous pouvez voir ces impacts dans les différentes infobulles du jeu.
                        Certains indices sont aussi indiqués dans les infobulles (icônes "i") de cet écran.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Choix stratégiques</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_6-1.png">
                <ul>
                    <li>Les <b>Choix stratégiques</b> apparaissent en début de tour, 6-7 fois environ au cours des 10 ans.
                        Ce sont des choix qui représentent les transitions à long terme pour votre exploitation
                        (transition bio, tourisme à la ferme, engagement bas carbone…).</li>
                    <li>Vous devez choisir entre 2 ou 3 voies, chacune avec des objectifs à atteindre différents.
                        Elles ont aussi un nombre de tours différent pour atteindre ces objectifs.</li>
                    <li>En sélectionnant une voie, vous gagnez immédiatement ses bonus.
                        Ces récompenses vous seront donnés uniquement si vous accomplissez les objectifs.</li>
                </ul>
                <img src="assets/help/jouer_6-2.png">
                <p>
                    Le menu <b>Objectifs</b> en haut à gauche vous permet de voir l’historique de tous vos choix effectués.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Recherches</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_7.png">
                <ul>
                    <li>Le menu Recherches en haut à gauche donne accès à de nouvelles techniques ou améliorations pour votre exploitation.
                        C’est un investissement, en nombre de tours, à moyen terme.</li>
                    <li>Les recherches terminées s’affichent en vert, celles en cours en bleu, celles débloquées en jaune, et celles bloquées en gris.</li>
                    <li>On ne peut faire qu’une recherche à la fois, mais il est possible au cours du jeu d’augmenter cette limite.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Marché</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_8-1.png">
                <ul>
                    <li>La partie <b>Achat</b> du Marché vous permet d’accéder à du nouveau matériel, utile pour certaines techniques avancées.</li>
                    <li>C’est aussi par ce biais que vous pouvez acheter les semences. Attention à bien acheter des semences de saison !
                        Ces informations se trouvent dans les infobulles, n’hésitez pas à les lire. Un calendrier sur la droite vous indique les mois de semis (en bleu) et les mois de récolte (en vert).</li>
                </ul>
                <img src="assets/help/jouer_8-2.png">
                <ul>
                    <li>La partie <b>Revente</b> du Marché permet de revendre tout ce qui ne passe pas par des contrats.</li>
                    <li>Les contrats vous permettent de revendre vos productions via des circuits de distribution.
                        Des circuits plus exigeants et rémunérateurs se débloquent à mesure que votre score augmente.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Équipe</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_9-1.png">
                <ul>
                    <li>Le menu <b>Équipe</b> permet de voir l’ensemble des membres qui composent actuellement votre équipe, ainsi que leurs contrats.</li>
                    <li>Le niveau global représente l’addition de leurs niveaux dans chaque domaine de compétence,
                        que vous pouvez filtrer avec les boutons en haut à gauche. Plus une personne travaille en “Cultures”,
                        plus son niveau en “Cultures” va augmenter, et plus elle travaillera efficacement.</li>
                    <li>La charge de travail représente la charge sur ce tour. Au-delà de 100 %, l’endurance et le moral baissent à la fin du tour.
                        Et inversement.</li>
                    <li>Si l’endurance ou le moral sont dans le rouge, l’indicateur Social baisse, et inversement.</li>
                </ul>
                <img src="assets/help/jouer_9-2.png">
                <ul>
                    <li>En cliquant sur le <b>portrait d’un personnage</b>, vous pouvez voir le nombre d’actions quotidiennes
                        auxquelles il est assigné chaque tour.</li>
                    <li>Vous pouvez aussi le former dans les différentes compétences, il vous suffit de cliquer sur les boutons “+” à droite.</li>
                </ul>
                <img src="assets/help/jouer_9-3.png">
                <ul>
                    <li>Le menu <b>Mon personnage</b> permet de voir le personnage qu’on avait sélectionné.</li>
                    <li>On peut régler le salaire qu’on se verse chaque mois. Un salaire élevé augmente l’indicateur Social et inversement.</li>
                </ul>
                <img src="assets/help/jouer_9-4.png">
                <ul>
                    <li>Le menu <b>Recrutement</b> permet de recruter de nouveaux membres dans l’équipe.</li>
                    <li>L’indicateur Social augmente si vous avez une équipe stable.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Événements</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_10.png">
                <ul>
                    <li>Au début de certains tours, des événements positifs ou négatifs peuvent survenir.
                        Ils représentent les aléas que rencontrent les agriculteurs (météo, cours du marché, etc.)</li>
                    <li>Certains vous demandent parfois de faire des choix.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Finances</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_11-1.png">
                <p>
                    La <b>Trésorerie</b> du menu Finance vous permet de voir l’évolution de votre trésorerie au cours des 10 ans, mois par mois.
                </p>
                <img src="assets/help/jouer_11-2.png">
                <ul>
                    <li>Le menu <b>Emprunt</b> des Finances vous permet d’emprunter de l’argent à la banque.</li>
                    <li>Des emprunts “trésorerie” sont directement accessibles dans ce menu et permettent de renflouer votre trésorerie.</li>
                    <li>D’autres sont disponibles uniquement à l’achat de nouveau matériel, bâtiment ou amélioration de bâtiment.</li>
                    <li>Chaque emprunt requiert un score minimum.</li>
                </ul>
                <img src="assets/help/jouer_11-3.png">
                <ul>
                    <li>Le menu <b>Assurances</b> des Finances vous permet de souscrire à des assurances.</li>
                    <li>Différents niveaux de franchise sont accessibles, vous permettant de vous couvrir plus largement contre les événements négatifs.</li>
                </ul>
                <img src="assets/help/jouer_11-4.png">
                <ul>
                    <li>Le menu <b>Autres produits</b> des Finances regroupe d’autres produits à souscrire.</li>
                    <li>L’épargne retraite est un simple On/Off, tandis que le package “Vente directe” s’active automatiquement quand vous installez une boutique.</li>
                    <li>Le livret de gestion vous permet de déposer de l’argent. Sélectionnez un montant que vous voulez déposer chaque mois. Cliquez sur le bouton “Tout récupérer” quand vous avez besoin de récupérer l’argent.</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Inventaire</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_12.png">
                <p>
                    L’inventaire vous permet, comme son nom l’indique, de répertorier tout ce qui vous appartient.
                    Utile pour faire le point de temps en temps sur vos productions, votre cheptel, etc.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Système de sauvegarde</mat-panel-title>
                </mat-expansion-panel-header>
                <img src="assets/help/jouer_13.png">
                <p>
                    Dans le menu paramètres en haut à gauche, vous pouvez sauvegarder et charger une partie.
                </p>
                <ul>
                    <li>Le jeu sauvegarde automatiquement à chaque tour, et garde les 5 dernières sauvegardes.</li>
                    <li>La fonction de sauvegarde manuelle n’est pas accessible en mode partie challenge ou enseignement.</li>
                </ul>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-tab>
    <mat-tab label="Nous contacter">
        <div style="margin-left: 10px; margin-top: 10px;">
            <p>Si vous n’avez pas trouvé réponse à votre question dans cette FAQ, vous pouvez toujours nous écrire un message via ce formulaire.</p>
            <form class="vertical" [formGroup]="mailForm" (ngSubmit)="sendMessage()">
                <p><b>Votre nom (obligatoire)</b></p>
                <mat-form-field appearance="outline">
                    <input matInput required type="text" formControlName="senderName">
                </mat-form-field>
                <p><b>Votre e-mail (obligatoire)</b></p>
                <mat-form-field appearance="outline">
                    <input matInput required type="text" formControlName="senderEmail">
                </mat-form-field>
                <p><b>Sujet (obligatoire)</b></p>
                <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="subject">
                </mat-form-field>
                <p><b>Votre message (obligatoire)</b></p>
                <mat-form-field appearance="outline" style="width: 500px;">
                    <textarea matInput formControlName="mailContent"></textarea>
                </mat-form-field>
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="!mailForm.valid">
                    Envoyer
                </button>
            </form>
        </div>
    </mat-tab>
</mat-tab-group>
