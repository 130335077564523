import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleService } from '../services/role.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};


export interface Group {
  _id: string,
  name: string,
  owners: string[],
  maxMembers: number,
}

export interface GroupTeacher{
  _id: string,
  members : UserTeacher[];
}

export interface UserTeacher{
  _id: string,
  emails: string[],
}

export interface User {
  _id: string,
  email: string,
  password: string,
  organization: string,
  license : string,
  role : string[],
}

export interface Game {
  name: string,
  owners: string[],
  visibility: string,
  startDate: Date,
  endDate: Date,
  group: string,
  properties: {
    type: string,
    difficulty: number,
    turnLength: number,
    turnUnit: number,
    adviserHelp: boolean,
    tutorial: boolean,
    scenario: number,
    ratings: {
      environmental: number,
      economical: number,
      social: number,
      sanitary: number,
      global: number
    },
    researchGoal: string,
    objectiveGoal: string
  }
}

export interface IdValuePair {
  id: string,
  value: string,
}

export interface ScenarioDataPair {
  scenario: number,
  data: IdValuePair[],
}

@Component({
  selector: 'app-game-create',
  templateUrl: './game-create.component.html',
  styleUrls: ['./game-create.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})
export class GameCreateComponent implements OnInit {
  public gameForm = new FormGroup({
    scenario: new FormControl(-1, Validators.min(0)),
    research: new FormControl(-1),
    objective: new FormControl(-1),
    startDate: new FormControl(null, Validators.required),
    startTime: new FormControl('', Validators.required),
    endDate: new FormControl(null, Validators.required),
    endTime: new FormControl('', Validators.required),
    class: new FormControl(null),
    gameMaxPlayers: new FormControl(10, [Validators.required, Validators.min(1), Validators.max(1000)]),
    gameName: new FormControl('', [Validators.required, Validators.maxLength(80), Validators.pattern(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9 .,;:&/!?+=\-*()]*$/)]),
    visibility: new FormControl(''),
  });

  public turnSliderValue = 2;

  public scenarios = [
    {id: 1, value: 'Bretagne - Porcin'},
    {id: 2, value: 'PACA - Ovins'},
    {id: 3, value: 'Grand Est - Bovins'},
    {id: 4, value: 'Normandie - Vaches laitières'},
    {id: 6, value: 'Grand Est - Grandes Cultures'},
    {id: 7, value: 'Grand Est - Cheptel Mixtes'},
    {id: 8, value: 'AURA - Bovins Allaitants'},
  ];

  public environmentSliderValue = 3;
  public economicSliderValue = 3;
  public socialSliderValue = 3;
  public globalSliderValue = 4;

  public researchs: ScenarioDataPair[] = [];
  public currentResearchs: IdValuePair[] = [];

  public objectives: ScenarioDataPair[] = [];
  public currentObjectives: IdValuePair[] = [];

  public minDate: Date = new Date();
  public maxDate: Date = new Date();

  public groups: Group[] = [];
  public groupTeacher: GroupTeacher;

  public isGamePlayerLimited = true;

  public isGamePosted = false;
  public isGamePrivate = false;
  public gameCode = "";

  private researchJsonURL = "assets/technos.json";
  private objectivesJsonURL = "assets/choices.json";

  private mailIsSending : boolean;

  constructor(
    private apiService: ApiService,
    public roleService: RoleService,
    private router: Router,
    private snackBar: MatSnackBar,
    private http: HttpClient,
  ) {
      this.getJSON(this.researchJsonURL).subscribe((data) => {
        this.setScenarioDataArrayFromJson(this.researchs, data);
      });

      this.getJSON(this.objectivesJsonURL).subscribe((data) => {
        this.setScenarioDataArrayFromJson(this.objectives, data);
      });
    }

  ngOnInit(): void {
    this.gameForm.get('class')?.setValidators(this.setRequired(['trainer']));
    this.gameForm.get('visibility')?.setValidators(this.setRequired(['manager']));

    this.apiService.getGroupsFromUser().subscribe((groups: any) => {
      this.groups = groups;
    }, (err: any) => {
      console.log('Error : ' + err);
    });

    this.maxDate.setFullYear(this.maxDate.getFullYear() + 50);
  }

  private setRequired(roleList: string[]): ValidatorFn[] {
    if (this.roleService.isRoleAllowed(roleList)) {
      return [Validators.required];
    } else {
      return [];
    }
  }

  public getMinimumDateForEnd(): Date {
    const startDate: Date = this.gameForm.controls['startDate'].value;

    if (startDate) {
      return startDate;
    }

    return new Date(Date.now());
  }

  private setDateTime(date: Date, time: string): Date {
    const timeArray = time.split(':');

    date.setHours(parseInt(timeArray[0]));
    date.setMinutes(parseInt(timeArray[1]));

    return date;
  }

  public notifyCopy(): void {
    this.snackBar.open('Code copié', 'OK');
  }

  public togglePlayerLimit(isGamePlayerLimited: boolean): void {
    this.isGamePlayerLimited = isGamePlayerLimited;
  }

  public postGame(): void {
    this.isGamePrivate = this.gameForm.controls['visibility'].value == 'private';

    let maxPlayers = this.gameForm.controls['gameMaxPlayers'].value;
    const visibility = this.gameForm.controls['visibility'].value;

    if (this.roleService.isRoleAllowed(['trainer'])) {
      maxPlayers = null;
    }

    this.apiService.getCurrentUserDetails().subscribe((userId) => {
      let startDateObject = this.gameForm.controls['startDate'].value._i;
      let endDateObject = this.gameForm.controls['endDate'].value._i;

      // The month need to be incremented because I don't know why endDateObject = 9 is 8 in the new date
      let newDateStart = new Date(Date.parse(startDateObject.year+'-'+ (startDateObject.month+1) +'-'+startDateObject.date));
      let newDateEnd = new Date(Date.parse(endDateObject.year+'-'+ (endDateObject.month+1) +'-'+endDateObject.date));

      //console.log(newDateStart.getFullYear()+'-'+newDateStart.getMonth()+'-'+newDateStart.getDate());
      //console.log(newDateEnd.getFullYear()+'-'+newDateEnd.getMonth()+'-'+newDateEnd.getDate());

      let startDate = this.setDateTime(newDateStart, this.gameForm.controls['startTime'].value);
      let endDate = this.setDateTime(newDateEnd, this.gameForm.controls['endTime'].value);

      if (startDate < new Date()) {
        startDate = new Date();
      }

      if (endDate < startDate) {
        endDate = startDate;
      }

      const gameObject: Game = {
        name: this.gameForm.controls['gameName'].value,
        owners: [userId],// Just a single owner for now (see if there needs to be an option to have more on create)
        visibility: visibility === '' ? 'group' : visibility,
        startDate: startDate,
        endDate: endDate,
        group: this.gameForm.controls['class'].value,
        properties: {
          type: 'Game',
          difficulty: 1,
          turnLength: this.turnSliderValue,
          turnUnit: 5218,// Default, check if it needs to be changed
          adviserHelp: true,
          tutorial: true,
          scenario: this.gameForm.controls['scenario'].value,
          ratings: {
            environmental: this.environmentSliderValue,
            economical: this.economicSliderValue,
            social: this.socialSliderValue,
            sanitary: 3,
            global: this.globalSliderValue
          },
          researchGoal: this.gameForm.controls['research'].value,
          objectiveGoal: this.gameForm.controls['objective'].value
        }
      };

      this.apiService.postGame(gameObject).subscribe((game) => {
        this.isGamePosted = true;
        this.gameCode = game.code;

        if (this.roleService.isRoleAllowed(['manager'])) {
          if (!this.isGamePlayerLimited) {
            maxPlayers = null;
          }

          const groupObject: Group = {
            _id: '',
            name: gameObject.name + '_' + this.gameCode,
            owners: [userId],
            maxMembers: maxPlayers,
          };

          this.apiService.postGroup(groupObject).subscribe((group) => {
            game.group = group._id;

            this.apiService.updateGame(game._id, game).subscribe((newGame) => {});
          });
        }
      }, (err: any) => {
        console.log('Error : ' + err);
      });
    }, (err: any) => {
      console.log('Error : ' + err);
    });
  }

  public getJSON(jsonURL: string): Observable<any> {
    return this.http.get(jsonURL);
  }

  public sendMailToClassGame() : void{
    var groupID : string  =  this.gameForm.controls['class'].value
    var gameName : string  =  this.gameForm.controls['gameName'].value

    //Get All Students Id From Group
    this.apiService.getUsersIdFromGroupId(groupID).subscribe((group: GroupTeacher) => {
      group.members.forEach(member => {
        member.emails.forEach(email => {
            this.apiService.sendSpecificMailToUser(email, {
              subject : "Partie : " + gameName,
              text : "Rejoignez votre exploitation agricole !\n" +
                "\n" +
                "Une partie de Roots of Tomorrow a été créée par votre enseignant. \n" +
                "\n" +
                "Installez le jeu à partir de ce lien : https://rootsoftomorrow.gamabilis.com/ puis lancez le jeu. \n" +
                "\n" +
                "Une fois en jeu, vous pourrez rejoindre la partie en cliquant sur le bouton “Rejoindre partie” et en vous connectant avec le nom d’utilisateur et le mot de passe que vous avez reçu dans le mail “Création de compte”.\n" +
                "\n" +
                "Une fois connecté, cliquez à nouveau sur “Rejoindre Partie”, puis “Parties en cours”, pour trouver la partie créée par votre enseignant. Assurez-vous d’avoir le bon nom de partie, puis cliquez sur “Salon”.\n" +
                "\n" +
                "Vous verrez ensuite le scénario choisi, les règles et les objectifs de la partie. \n" +
                "\n" +
                "Si la partie est accessible, vous pouvez désormais cliquer sur le bouton “Jouer” !\n" +
                "\n" +
                "Restant à votre disposition\n" +
                "\n" +
                "L'équipe Gamabilis\n" +
                "\n" +
                "support.challenge@gamabilis.com"
            }).subscribe(() => {
              this.snackBar.open('Votre mail a été envoyé', 'OK', {
                duration: 5000
              });
            }, (error) => {
              console.log(error);
              this.snackBar.open('Un problème est survenu lors de l\'envoi du mail', 'OK', {
                duration: 5000
              });
            });
        });
      });
    }, (err: any) => {
      console.log('Error Get Users Id: ' + err);
    });
  }

  public getIdValuePairArray(event: Event): void {
    const scenarioId: number = this.gameForm.controls['scenario'].value;
    const researchScenarioDataPair = this.researchs.find((scenarioDataPair) => { return scenarioDataPair.scenario === scenarioId });
    const objectivesScenarioDataPair = this.objectives.find((scenarioDataPair) => { return scenarioDataPair.scenario === scenarioId });

    this.currentResearchs = researchScenarioDataPair !== undefined ? researchScenarioDataPair.data : [];
    this.currentObjectives = objectivesScenarioDataPair !== undefined ? objectivesScenarioDataPair.data : [];
  }

  private setScenarioDataArrayFromJson(array: ScenarioDataPair[], jsonData: any): void {
    const scenarioCount = jsonData.dictionaryList.length

    for (let i = 0; i < scenarioCount; i++) {
      const limit = jsonData.dictionaryList[i].m_keys.length;
      let idValuePairArray: IdValuePair[] = [];

      for (let j = 0; j < limit; j++) {
        idValuePairArray.push({id: jsonData.dictionaryList[i].m_keys[j], value: jsonData.dictionaryList[i].m_values[j]});
      }

      array.push({scenario: i + 1, data: idValuePairArray});
    }
  }
}
